import React from "react";
import Company from "../../components/company/Company";
import WhyChooseTysonWorks from "../../components/whychoosetysonworks/WhyChooseTysonWorks";
import CaseStudies from "../../components/casestudies/CaseStudies";
import Team from "../../components/team/Team";
import Testimonials from "../../components/testimonials/Testimonials";
import Timeline from "../../components/timeline/Timeline";
import Certifications from "../../components/certifications/Certifications";
import ScrollReveal from '../../components/scroll/ScrollReveal';
import "./About.css";

const About = () => {
  return (
    <React.Fragment>
      <div id="about" className="main-content">
        {/* Company Overview Section */}
        <ScrollReveal>
          <Company />
        </ScrollReveal>
        
        {/* Why Choose Us Section */}
        <ScrollReveal delay={200}>
          <WhyChooseTysonWorks />
        </ScrollReveal>
        
        {/* Testimonials Section */}
        <ScrollReveal delay={300}>
          <Testimonials />
        </ScrollReveal>
        
        {/* Case Studies Section */}
        {/* <ScrollReveal delay={200}>
          <CaseStudies />
        </ScrollReveal> */}
        
        {/* Team Section */}
        {/* <ScrollReveal delay={300}>
          <Team />
        </ScrollReveal> */}
        
        {/* Certifications Section */}
        <ScrollReveal delay={200}>
          <Certifications />
        </ScrollReveal>
        
        {/* Company Timeline Section */}
        <ScrollReveal delay={300}>
          <Timeline />
        </ScrollReveal>
        
        {/* Call to Action */}
        {/* <ScrollReveal delay={200}>
          <div id="about-cta">
            <div className="cta-container">
              <h2>Ready to Transform Your Cloud Infrastructure?</h2>
              <p>Let's discuss how we can help you achieve your cloud goals.</p>
              <div className="cta-buttons">
                <a href="/contact" className="primary-button">Schedule a Consultation</a>
                <a href="/services" className="secondary-button">Explore Our Services</a>
              </div>
            </div>
          </div>
        </ScrollReveal> */}
      </div>
    </React.Fragment>
  );
};

export default About;
