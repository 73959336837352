import React from "react";
import "./Certifications.css";

import AWS_BADGE from "../../assets/images/apn_badge.svg";
import CKA_LOGO from "../../assets/images/cka_logo.png";
import CNCF_LOGO from "../../assets/images/CNCF.svg";
import KUBECON_LOGO from "../../assets/images/Kubecon_Horiz.svg";

const Certifications = () => {
  return (
    <section id="certifications">
      <div className="grid section">
        <div className="col-12_sm-12">
          <h2 className="head">Our Certifications & Partnerships</h2>
          <p className="subtitle">Industry-recognized expertise you can trust</p>
        </div>
      </div>
      <div className="certifications-container">
        <div className="certification-group">
          <h3>AWS Certifications</h3>
          <div className="cert-items">
            <div className="cert-item">
              <div className="cert-logo">
                <img src={AWS_BADGE} alt="AWS Partner Network" />
              </div>
              <div className="cert-details">
                <h4>AWS Partner Network</h4>
                <p>Advanced Consulting Partner</p>
              </div>
            </div>
            <div className="cert-item">
              <div className="cert-logo aws-cert">
                <span className="aws-cert-icon">AWS</span>
              </div>
              <div className="cert-details">
                <h4>AWS Solutions Architect</h4>
                <p>Professional Level Certification</p>
              </div>
            </div>
            <div className="cert-item">
              <div className="cert-logo aws-cert">
                <span className="aws-cert-icon">AWS</span>
              </div>
              <div className="cert-details">
                <h4>AWS DevOps Engineer</h4>
                <p>Professional Level Certification</p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="certification-group">
          <h3>Kubernetes & Cloud Native</h3>
          <div className="cert-items">
            <div className="cert-item">
              <div className="cert-logo">
                <img src={CKA_LOGO} alt="Certified Kubernetes Administrator" />
              </div>
              <div className="cert-details">
                <h4>Certified Kubernetes Administrator</h4>
                <p>Linux Foundation Certification</p>
              </div>
            </div>
            <div className="cert-item">
              <div className="cert-logo">
                <img src={CNCF_LOGO} alt="Cloud Native Computing Foundation" />
              </div>
              <div className="cert-details">
                <h4>CNCF Member</h4>
                <p>Cloud Native Computing Foundation</p>
              </div>
            </div>
            <div className="cert-item">
              <div className="cert-logo">
                <img src={KUBECON_LOGO} alt="KubeCon Participant" />
              </div>
              <div className="cert-details">
                <h4>KubeCon Participant</h4>
                <p>Annual Cloud Native Conference</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Certifications;
