import React from "react";
import "./Timeline.css";

const Timeline = () => {
  const milestones = [
    {
      id: 1,
      year: "2022",
      title: "TysonWorks Founded",
      description: "Founded with a mission to make enterprise-grade AWS expertise accessible to growing businesses."
    },
    {
      id: 2,
      year: "2022",
      title: "AWS Partner Network",
      description: "Joined the AWS Partner Network, gaining access to advanced training and resources."
    },
    {
      id: 3,
      year: "2023",
      title: "First Enterprise Client",
      description: "Secured our first enterprise healthcare client, helping them achieve HIPAA compliance on AWS."
    },
    {
      id: 4,
      year: "2023",
      title: "Team Expansion",
      description: "Expanded our team of certified AWS experts to better serve our growing client base."
    },
    {
      id: 5,
      year: "2024",
      title: "Advanced AWS Partner Status",
      description: "Achieved Advanced Partner status in the AWS Partner Network."
    },
    {
      id: 6,
      year: "2025",
      title: "Looking Forward",
      description: "Continuing to innovate and help businesses harness the full power of AWS cloud technology."
    }
  ];

  return (
    <section id="company-timeline">
      <div className="grid section">
        <div className="col-12_sm-12">
          <h2 className="head">Our Journey</h2>
          <p className="subtitle">Key milestones in our company's history</p>
        </div>
      </div>
      <div className="timeline-container">
        {milestones.map((milestone, index) => (
          <div 
            key={milestone.id} 
            className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'}`}
          >
            <div className="timeline-content">
              <div className="year-badge">{milestone.year}</div>
              <h3>{milestone.title}</h3>
              <p>{milestone.description}</p>
            </div>
          </div>
        ))}
        <div className="timeline-line"></div>
      </div>
    </section>
  );
};

export default Timeline;
