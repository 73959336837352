import React from "react";
import Offerings from "../../components/offerings/Offerings";
import ScrollReveal from '../../components/scroll/ScrollReveal';
import "./Services.css";

const Services = () => {
  return (
    <React.Fragment>
      <div id="services-page" className="main-content">
        {/* Hero Section */}
        {/* <ScrollReveal>
          <div id="services-hero">
            <div className="hero-content">
              <h1>AWS Cloud Solutions for Growing Businesses</h1>
              <p>Enterprise-grade AWS expertise at startup-friendly prices. Choose from flexible packages or on-demand services.</p>
              <div className="hero-buttons">
                <a href="#monthly-services" className="primary-button">View Packages</a>
                <a href="#services" className="secondary-button">Explore Services</a>
              </div>
            </div>
          </div>
        </ScrollReveal> */}
        
        {/* Services Offerings */}
        <ScrollReveal delay={200}>
          <Offerings />
        </ScrollReveal>
        
        {/* Process Section */}
        <ScrollReveal delay={300}>
          <div id="services-process">
            <div className="process-content">
              <h2>Our Simple 3-Step Process</h2>
              <p className="process-description">Getting started with TysonWorks is quick and straightforward</p>
              
              <div className="process-steps">
                <div className="process-step">
                  <div className="step-number">1</div>
                  <h3>Discovery Call</h3>
                  <p>We'll discuss your needs, challenges, and goals to understand how we can best help your business.</p>
                </div>
                <div className="process-step">
                  <div className="step-number">2</div>
                  <h3>Custom Solution</h3>
                  <p>We'll create a tailored plan that addresses your specific AWS infrastructure requirements.</p>
                </div>
                <div className="process-step">
                  <div className="step-number">3</div>
                  <h3>Implementation</h3>
                  <p>Our team will execute the plan efficiently, with clear communication throughout the process.</p>
                </div>
              </div>
            </div>
          </div>
        </ScrollReveal>
        
        {/* Testimonials Section
        <ScrollReveal delay={200}>
          <div id="services-testimonials">
            <h2>What Our Clients Say</h2>
            <div className="testimonials-container">
              <div className="testimonial">
                <div className="quote">"TysonWorks cut our AWS bill by 45% while improving our infrastructure reliability. Their team is responsive and knowledgeable."</div>
                <div className="author">
                  <div className="author-info">
                    <div className="author-name">David Chen</div>
                    <div className="author-title">CTO, HealthTech Startup</div>
                  </div>
                </div>
              </div>
              <div className="testimonial">
                <div className="quote">"Working with TysonWorks helped us achieve SOC2 compliance in just 6 weeks. Their expertise saved us months of work."</div>
                <div className="author">
                  <div className="author-info">
                    <div className="author-name">Sarah Johnson</div>
                    <div className="author-title">VP of Engineering, FinTech Company</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollReveal> */}
        
        {/* FAQ Section */}
        <ScrollReveal delay={300}>
          <div id="services-faq">
            <h2>Frequently Asked Questions</h2>
            <div className="faq-container">
              <div className="faq-item">
                <h3>How quickly can you start working on our AWS infrastructure?</h3>
                <p>For most clients, we can begin work within 48 hours of signing an agreement. For urgent issues, we often provide same-day assistance.</p>
              </div>
              <div className="faq-item">
                <h3>Do you require long-term contracts?</h3>
                <p>No. Our monthly packages are month-to-month with no long-term commitment required. For project-based work, we define clear deliverables and timelines.</p>
              </div>
              <div className="faq-item">
                <h3>How do you handle security and compliance?</h3>
                <p>We implement AWS security best practices by default and have extensive experience with SOC2, HIPAA, and other compliance frameworks.</p>
              </div>
              <div className="faq-item">
                <h3>What if we already have some AWS infrastructure in place?</h3>
                <p>We'll perform a thorough assessment of your existing infrastructure and provide recommendations for improvements while working with what you already have.</p>
              </div>
            </div>
          </div>
        </ScrollReveal>
      </div>
    </React.Fragment>
  );
};

export default Services;
