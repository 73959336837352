import React from "react";
import { useHistory } from 'react-router-dom';
import "./Splash.css";
import APN_BADGE from "../../assets/images/apn-badge.png"
import MAIN_IMAGE from "../../assets/images/business.svg";

const APN_LISTING = "https://aws.amazon.com/partners/find/partnerdetails/?id=0010h00001aCbizAAC";
const IQ_LISTING = "https://iq.aws.amazon.com/c/tysonworks";

const Splash = (props) => {
    return (
        <div id="jumbotron">
            <div className="grid" id="below-nav">
                <div className="col-6_md-12" id="left-panel">
                    <div className="text">
                        <div className="head">Elevate Your Business with Modern Cloud Solutions</div>
                        <div className="body">
                            <p>We partner with ambitious companies to design, build, and manage cloud infrastructures that scale with your business needs. Our expert team delivers reliable AWS solutions on time and within budget.</p>
                            <div id="splash-section">
                                <ul id="services">
                                    <li>Monthly Cloud Management</li>
                                    <li>Cloud Architecture & Infrastructure Design</li>
                                    <li>DevOps Automation & CI/CD</li>
                                    <li>Compliance Readiness (HIPAA & SOC2)</li>
                                    <li>AWS Marketplace Integration</li>
                                    <li>FinOps & Cost Optimization</li>
                                </ul>
                                <a href={IQ_LISTING} target="_blank" rel="noopener noreferrer" className="badge-container">
                                    <img alt="AWS Partner Network Badge" id="apn-badge" src={APN_BADGE}></img>
                                </a>
                            </div>
                            <div className="row">
                                <CalendlyPopup />
                                <ServicesButton />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6_md-12">
                    <div id="jumbotron-img" className="hero">
                        <div className="img"><img alt="Cloud Solutions Illustration" src={MAIN_IMAGE} /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CalendlyPopup() {
    function handleClick(e) {
        e.preventDefault();
        window.Calendly.initPopupWidget({ url: 'https://calendly.com/tysonworks/30mins' })
    }

    return (
        <div id='calendly-section'>
            <button className="button-solid" onClick={handleClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ marginRight: '8px', verticalAlign: 'text-bottom' }}>
                    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                    <line x1="16" y1="2" x2="16" y2="6"></line>
                    <line x1="8" y1="2" x2="8" y2="6"></line>
                    <line x1="3" y1="10" x2="21" y2="10"></line>
                </svg>
                Schedule Free Consultation
            </button>
        </div>
    );
}

function ServicesButton() {
    const history = useHistory();

    const navigateToServices = () => {
        history.push('/services');
    };

    return (
        <div id='services-section'>
            <button className="button-solid" onClick={navigateToServices}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ marginRight: '8px', verticalAlign: 'text-bottom' }}>
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" y1="8" x2="12" y2="16"></line>
                    <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>
                Explore Our Services
            </button>
        </div>
    );
}

export default Splash;