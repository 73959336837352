import React from "react";
import "./Testimonials.css";

const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      quote: "TysonWorks transformed our AWS infrastructure. We're now saving over 40% on our monthly cloud costs while getting better performance.",
      author: "Sarah Johnson",
      position: "CTO, MedicHands",
      company: "MedicHands"
    },
    {
      id: 2,
      quote: "Their team's expertise in AWS and cloud security helped us achieve HIPAA compliance with confidence. Highly recommended for healthcare companies.",
      author: "Michael Chen",
      position: "Director of Engineering",
      company: "MediCloud"
    },
    {
      id: 3,
      quote: "Working with TysonWorks has been a game-changer for our startup. They helped us build a scalable infrastructure that grew with us from seed to Series B.",
      author: "Alex Rodriguez",
      position: "Founder & CEO",
      company: "Ziran Biopharmaceutical"
    }
  ];

  return (
    <section id="testimonials">
      <div className="grid section">
        <div className="col-12_sm-12">
          <h2 className="head">What Our Clients Say</h2>
          <p className="subtitle">Trusted by innovative companies across industries</p>
        </div>
      </div>
      <div className="testimonials-container">
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="testimonial-card">
            <div className="quote-icon">"</div>
            <p className="quote-text">{testimonial.quote}</p>
            <div className="testimonial-author">
              <div className="author-info">
                <h4>{testimonial.author}</h4>
                <p>{testimonial.position}, {testimonial.company}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
